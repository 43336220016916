<template>
  <v-form
    ref="form"
    v-model="validForm"
  >
    <v-row >
      <v-col cols="12" md="3" class="py-0">
        <v-menu
          v-model="menuDate"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="requestQuote.date"
              label="Fecha"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="requestQuote.date"
            :min="mindate"
            @input="menuDate = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="12" md="2" class="py-0">
        <v-select
          v-model="requestQuote.guides"
          :items="guides"
          label="Guias"
          persistent-hint
          prepend-icon="mdi-diving-helmet"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="12" md="2" class="py-0">
        <v-select
          v-model="requestQuote.pax"
          :items="pax"
          label="Clientes"
          persistent-hint
          prepend-icon="mdi-diving-scuba"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="12" md="3" class="py-0">
        <v-select
          v-model="requestQuote.immersions"
          :items="immersions"
          label="Inmersiones extras"
          persistent-hint
          prepend-icon="mdi-diving-scuba-tank-multiple"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="12" md="2" class="py-0">
        <v-select
          v-model="requestQuote.currency"
          :items="currencies"
          label="Moneda"
          persistent-hint
          item-text="code"
          item-value="id"
          prepend-icon="mdi-currency-usd"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="12" md="12" class="py-0">
        <p v-if="error" class="error--text">{{ error.message }}</p>
        <v-btn
          class="mt-md-3"
          small
          elevation="2"
          color="primary"
          @click="getQuote()"
        >
          Cotizar
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
  import firebase from 'firebase/app';
  import 'firebase/auth';
  import {Quote} from "@/quoteDivers";
  import {Request} from "@/quoteDivers/Request";
  export default {
    name: 'DiversFormQuote',
    props:{
      request:{
        type: Object,
        required:true
      },
      currencies:{
        required:true
      }
    },
    data() {
      let currentUser = firebase.auth().currentUser;
      let pax = []
      for (var i = 1; i <= 9; i++) {
        pax.push(i)
      }
      let date = this.$moment()
      return {
        mindate:date.format('YYYY-MM-DD'),
        validForm:true,
        menuDate:false,
        immersions:[0,1,2,3,4,5,6,7,8,9,10],
        guides:[1,2,3],
        pax,
        error: null,
        requestQuote: {uid:currentUser.uid, ...this.request, currency: this.currencies[0].id},
        uid: currentUser.uid
      }
    },
    methods: {
      async getQuote() {
        if (this.$refs.form.validate()) {
          let rqData = {...this.requestQuote}
          const request = new Request(rqData, {moment:this.$moment})
          try {
            this.error = null 
            request.validate()
            this.$emit('init-quote', {reques:request})
            const quote = new Quote(request)
            await quote.setDiversConfigs()
            this.$emit('finish-quote', {
              request:request,
              prices: await quote.getPrices(),
              availableExtras: await quote.getAvailableExtras(),
              availabilityInformation: quote.availabilityInformation(),
              schedules: quote.getSchedules()
            })
          } catch (error) {
            this.error = error 
            console.log('error',error)
            this.$emit('errpr-quote', {error:error})
          }
        }
      }
    }
  }
</script>