class Request {
  _data
  _options
  constructor(data, options) {
    this._data = data
    this._options = {
      moment: options.moment
    }
  }
  get date() {
    return this._data.date
  }
  get immersions() {
    return this._data.immersions
  }
  get pax() {
    return this._data.pax
  }
  get guides() {
    return this._data.guides
  }
  get currency() {
    return this._data.currency
  }
  get requiredGuides() {
    return this._requiredGuides
  }
  get uid() {
    return this._data.uid
  }

  get moment() {
    return this._options.moment
  }
  validate() {
    this._validateDate()
    this._validateCurrency()
    this._validateImmersions()
    this._validatePax()
    this._validateGuides()
    this._setRequiredGuides()
  }
  _validateDate() {
    const date = this._options.moment(this._data.date+' 23:59:59');
    if (! date.isValid()) {
      throw {error:'', message:'La fecha es invalida'}
    }
    const now = this._options.moment();
    if (date < now) {
      throw {error:'', message:'La fecha debe ser mayor a la fecha actual'}
    }
  }
  _validateCurrency() {
    const currency = this._data.currency || 'mxn'
    this._data.currency = currency.toLowerCase()
    if (!(this._data.currency == 'mxn' || this._data.currency == 'usd')) {
      throw {
        error: '',
        message: 'El número de inmersiones es invalido'
      }
    }
  }
  _validateImmersions() {
    var patt = /(^\d{1,10}$)/
    if (!String(this._data.immersions).match(patt)) {
      throw {
        error: '',
        message: 'El número de inmersiones es invalido'
      }
    }
  }
  _validatePax() {
    var patt = /(^\d{1,10}$)/;
    if (!String(this._data.pax).match(patt)) {
      throw {
        error: '',
        message: 'El número de clientes es invalido'
      }
    }
  }
  _validateGuides() {
    var patt = /(^\d{1,10}$)/
    if (!String(this._data.guides).match(patt)) {
      throw {
        error: '',
        message: 'El número de guias es invalido'
      }
    }
    const numberRequiredGuides = Math.ceil(this._data.pax / 4)
    if (this._data.guides < numberRequiredGuides) {
      throw {
        error: '',
        message: 'Recuerde que por cada 4 clientes es necesario un guía'
      }
    }
  }
  _setRequiredGuides() {
    this._requiredGuides = Math.ceil(this._data.pax / 4)
  }
  toJSON() {
    return {
      date: this.date,
      immersions: this.immersions,
      pax: this.pax,
      guides: this.guides,
      currency: this.currency,
      uid: this.uid,
    }
  }
}
export {Request}