<template>
	<div style="">
    <portal to="title-page">
      <h1>Inicio</h1>
    </portal>
    <Loading :active="loading"/>
    <v-row>
      <v-col cols="12" md="12">
        <v-expansion-panels
          v-model="panel"
          multiple
          class="mx-auto elevation-1"
        >
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3 class="v-card__title" style="padding: 0">Cotizador</h3>
            </v-expansion-panel-header>
            <v-divider></v-divider>
            <v-expansion-panel-content>
              <DiversForm
                v-if="currencies"
                :request="request"
                :currencies="currencies"
                @init-quote="initQuote"
                @finish-quote="finishQuote"
                @error-quote="errorQuote"
                />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row >
    <v-row class="mt-4">
      <v-col v-if="quote" cols="12" md="6">
        <QuoteExtras
          :quote="quote"
          :request="{type: 'foreign'}"
          :show-child="false"
          @quote-change-extra="quoteChangeExtra"
        />
      </v-col>
      <v-col v-if="quote" cols="12" md="6">
        <div class="reservation-totals">
          <h2 class="mb-2">Totales</h2>
          <template v-if="selectedExtras.length">
            <div v-for="(e, key) in selectedExtras" :key="key" >
              <h4 v-if="e.type == 'regular' && e.quantityAdults > 0" class="d-flex">
                <span>
                  {{ e.name }}  (1 = ${{ numberFormat(e.priceAdult) }}
                  <small>{{quote.request.currency}}</small>)
                </span>
                <span class="ml-auto">
                  ${{ numberFormat(e.quantityAdults * e.priceAdult) }}
                  <small>{{quote.request.currency}}</small>
                </span>
              </h4>
              <h4 v-if="e.type == 'atv' && e.distribution.single > 0" class="d-flex pl-2">
                <span>
                  Adultos en individuales {{ e.distribution.single }} x ${{ e.priceAdult }}
                  <small>{{quote.request.currency}}</small>
                </span>
                <span class="ml-auto">
                  ${{ e.distribution.single * e.priceAdult}}
                  <small>{{quote.request.currency}}</small>
                </span>
              </h4>
              <h4 v-if="e.type == 'atv'  && e.distribution.adultsShared > 0" class="d-flex pl-2">
                <span>
                  Adultos en compartidas {{ e.distribution.adultsShared }} x ${{ e.priceAdultShared }}
                  <small>{{quote.request.currency}}</small>
                </span>
                <span class="ml-auto">
                  ${{ e.distribution.adultsShared * e.priceAdultShared }}
                  <small>{{quote.request.currency}}</small>
                </span>
              </h4>
            </div>
          </template>
          <h4 class="d-flex">
            <span>
              Guias (1 = ${{numberFormat(quote.prices.diverPrice)}})
              <v-btn
                v-if="!quote.prices.discount"
                icon
                depressed
                color="info"
                x-small
                @click="showNotDiscountMessage = !showNotDiscountMessage"
              ><v-icon>mdi-help-box</v-icon></v-btn>
            </span>
            <span class="ml-auto">
              ${{numberFormat(quote.request.guides * quote.prices.diverPrice, 2, ',', '.')}}
              <small>{{quote.request.currency}}</small>
            </span>
          </h4>
          <v-alert
            v-if="showNotDiscountMessage"
            v-model="showNotDiscountMessage"
            dense
            border="left"
            text
            type="error"
            dismissible
          >
            Para que los guías no paguen debe tener al menos una reservacion en <strong>Checkin</strong> dentro de 90 dias anteriores a la fecha actual
          </v-alert>
          <h4 v-if="quote.prices.discount" class="d-flex red--text">
            <span>
              {{quote.prices.discount.title}}
              <v-btn
                icon
                depressed
                color="info"
                x-small
                @click="showDiscountMessage = !showDiscountMessage"
              ><v-icon>mdi-help-box</v-icon></v-btn>
            </span>
            <span class="ml-auto">
              -${{numberFormat(quote.prices.discount.value, 2, ',', '.')}}
              <small>{{quote.request.currency}}</small>
            </span>
          </h4>
          <v-alert
            v-if="showDiscountMessage"
            v-model="showDiscountMessage"
            dense
            border="left"
            text
            type="success"
            dismissible
          >
            {{quote.prices.discount.message}}
          </v-alert>
          <h4 class="d-flex">
            <span>Clientes (1 = ${{numberFormat(quote.prices.customerPrice)}}) </span>
            <span class="ml-auto">
              ${{numberFormat(quote.prices.totalCustomers, 2, ',', '.')}}
              <small>{{quote.request.currency}}</small>
            </span>
          </h4>
          <h4 class="d-flex">
            <span>Inmersiones extras (1 = ${{numberFormat(quote.prices.immersionPrice)}}) </span>
            <span class="ml-auto">
              ${{numberFormat(quote.prices.totalImmersions, 2, ',', '.')}}
              <small>{{quote.request.currency}}</small>
            </span>
          </h4>
          <v-divider></v-divider>
          <h3 class="border-top p-2 text-right">
            <span>Total</span><span class="ml-2">${{numberFormat(computedTotal,)}}<small>{{quote.request.currency}}</small></span>
          </h3>
          <v-row>
            <v-col md="12">
              <!-- <h2 class="mb-0 mt-5">Reservaciones </h2> -->
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left" colspan="4">
                        <p class="my-0">Reservaciones para el día {{ quote.request.date }}</p>
                      </th>
                    </tr>
                    <tr>
                      <th class="text-left">
                        Hora
                      </th>
                      <th class="text-left">
                        Guías
                      </th>
                      <th class="text-left">
                        Clientes
                      </th>
                      <th class="text-left">
                        Inmersiones
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="r in reservationsByDate" :key="r.id"
                    >
                      <td>{{$moment.unix(r.date.seconds).format("HH:mm")}}</td>
                      <td>{{ r.guides }}</td>
                      <td>{{ r.adults }}</td>
                      <td>{{ r.immersions }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col md="12">
              <h2 class="mb-2 mt-5">Guías</h2>
              <v-form ref="formBook" v-model="validform">
                <v-text-field
                  v-for="(i, index) in quote.request.guides"
                  :key="index"
                  v-model="reservation.guides[i-1]"
                  :rules="[v => !!v || 'Campo requerido']"
                  :label="`Nombre guía `+ i"
                  ></v-text-field>
                  <v-select
                    v-model="reservation.scheduleTime"
                    :items="availableSchedules"
                    label="Horario"
                    :rules="[v => !!v || 'Campo requerido']"
                  ></v-select>
                  <v-btn
                  class="mt-md-3"
                  small
                  elevation="2"
                  color="primary"
                  :disabled="availableSchedules.length == 0"
                  @click="book()"
                >
                  Reservar
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="-1"
    >
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color=""
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
	</div>
</template>
<script>
  import Vue from "vue";
  import Loading from "@/components/Loading";
  import DiversForm from "@/components/quote/DiversForm";
  import QuoteExtras from "@/components/quote/Extras";
  import firebase from 'firebase/app';
  import 'firebase/auth';
  import {NumberFormat} from "@/helpers/NumberFormat";
  const axios = require('axios');
  Vue.use('Loading', Loading);

  export default {
    name:'DiversDashboard',
    components:{DiversForm, QuoteExtras},
    created () {
      this.$store.commit('SET_LAYOUT', 'Divers')
    },
    data() {
      return {
        totalExtras:0,
        panel: [0],
        validform:true,
        showNotDiscountMessage:false,
        showDiscountMessage:false,
        loading:true,
        currencies:null,
        quote:null,
        request: {
          date:'',
          guides:1,
          pax:1,
          immersions:0,
        },
        snackbar:{
          show:false,
          color:'',
          message:''
        },
        reservation:{
          scheduleTime:'',
          guides:[]
        },
        snapReservations: null,
        schedules:[],
        unavailableSchedules:[],
        //schedulesAvailable:[],
        reservationsByDate:[]
      }
    },
    mounted() {
      firebase.auth().currentUser.getIdToken(true).then((idToken) => {
        var config = {
          method: 'get',
          url: process.env.VUE_APP_API_URL+'/api/currencies?lang=es',
          //data: {uid:this.uid, password: this.password},
          headers:{
            'token':idToken
          }
        };
        axios(config)
        .then(async (data) => {
          this.loading = false
          this.currencies = data.data
        })
        .catch((error) => {
          console.log(error);
          this.loading = false
          if (error.response && error.response.data && error.response.data.error) {
            this.showSnackbarError(error.response.data.error.message)
          } else {
            this.showSnackbarError('Error al obtener los datos del cotizador')
          }
        });
      }).catch((error) => {
        // Handle error
        console.log('error al obtener el token', error);
        this.loading = false
        this.showSnackbarError('Error al obtener los datos del cotizador')
      });
    },
    methods:{
      quoteChangeExtra() {
        this.totalExtras = 0;
        for (var i in this.quote.availableExtras) {
          if (this.quote.availableExtras[i].type === 'atv' && this.quote.availableExtras[i].distribution) {
            this.totalExtras += this.quote.availableExtras[i].distribution.total;
          } else {
            if (this.quote.availableExtras[i].quantityAdults > 0) {
              this.totalExtras += (this.quote.availableExtras[i].quantityAdults * this.getExtraPriceAdult(this.quote.availableExtras[i]));
            }
          }
        }
      },
      initQuote() {
        this.quote = null
        this.loading = true
      },
      finishQuote(data) {
        this.quote = data
        if (this.snapReservations) {
          this.snapReservations()
        }
        this.snapReservations = data.availabilityInformation.onSnapshot((snapshot) => {
          this.setAvailableInfo(snapshot)
          this.schedules = data.schedules
          
          snapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
              console.log("New reservation: ")
            }
        });
        })
        this.loading = false
      },
      setAvailableInfo(snapshot) {
        this.reservationsByDate = []
        this.unavailableSchedules = []
        snapshot.forEach((doc) => {
          const data = doc.data()
          this.reservationsByDate.push(data)
          this.unavailableSchedules.push(this.$moment.unix(data.date.seconds).format('HH:mm'))
        })
      },
      errorQuote(err) {
        console.log('erroQuote', err);
        this.loading = false
      },
      numberFormat(number) {
        return NumberFormat.format(number, 2, ',', '.')
      },
      showSnackbarSuccess(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'green';
      },
      showSnackbarError(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'red';
      },
      book() {
        if (this.$refs.formBook.validate()) {
          this.loading = true
          firebase.auth().currentUser.getIdToken(true).then((idToken) => {
            let lastReservation = null
            if (this.quote.prices.lastReservation) {
              lastReservation = {
                code: this.quote.prices.lastReservation.code,
                date: this.$moment.unix(this.quote.prices.lastReservation.date.seconds).format('YYYY-MM-DD HH:mm'),
              }
            }
            let config = {
              method: 'post',
              url: process.env.VUE_APP_API_URL+'/api/reservation-divers',
              data: {
                lastReservation,
                subtotal: this.quote.prices.totalCustomers+this.quote.prices.subtotalGuides,
                rate: this.quote.prices.rate,
                discount: this.quote.prices.discount,
                total: this.quote.prices.total + this.totalExtras,
                exchangeRate: this.quote.prices.exchangeRate,
                extras: this.getExtras(),
                ... this.quote.request.toJSON(),
                ... this.reservation
              },
              headers:{
                'token':idToken
              }
            };
            axios(config)
            .then(async (data) => {
              this.loading = false
              this.$router.push(
                {path:`/backend/divers/reservations/${data.data.id}`},
                () => {
                  this.loading = false
                }
              )
            })
            .catch((error) => {
              console.log(error);
              this.loading = false
              if (error.response && error.response.data && error.response.data.error) {
                this.showSnackbarError(error.response.data.error.message)
              } else {
                this.showSnackbarError('Error al crear la reservación')
              }
            });
          }).catch((error) => {
            // Handle error
            console.log('error al obtener el token', error);
            this.loading = false
            this.showSnackbarError('Error al crear la reservación')
          });
          console.log('resrvar');
        }
      },
      getExtraPriceAdult(extra) {
        return extra.rate.foreignAdult;
      },
      getExtras() {
        let total = 0
        let items = []
        for(const e of this.selectedExtras ) {
          if (e.quantityAdults > 0) {
            total += e.quantityAdults * e.priceAdult
            items.push(e)
          }
        }
        const subtotal = total
        return {
          total,
          subtotal,
          items
        }
      }
    },
    computed:{
      availableSchedules() {
        let schedulesAvailable = []
        this.schedules.forEach((item) => {
          const time = this.$moment.unix(item.time.seconds).format('HH:mm')
          if (this.unavailableSchedules.indexOf(time) < 0) {
            schedulesAvailable.push(time)
          }
        })
        return schedulesAvailable
      },
      selectedExtras() {
        var selectedEtras = [];
        for (var i in this.quote.availableExtras) {
          if (this.quote.availableExtras[i].type === 'atv' && this.quote.availableExtras[i].distribution) {
            selectedEtras.push({
              id: this.quote.availableExtras[i].id,
              name: this.quote.availableExtras[i].name,
              type: 'atv',
              distribution: this.quote.availableExtras[i].distribution,
              quantityAdults: this.quote.availableExtras[i].distribution.single + (this.quote.availableExtras[i].distribution.shared * 2),
              quantityChildren: this.quote.availableExtras[i].distribution.children,
              priceAdult: this.getExtraPriceAdult(this.quote.availableExtras[i]),
              priceAdultShared: this.getExtraPriceAdultShared(this.quote.availableExtras[i]),
              priceChild: null,
            })
          } else {
            if (this.quote.availableExtras[i].quantityAdults > 0 || this.quote.availableExtras[i].quantityChildren > 0) {
              selectedEtras.push({
                id: this.quote.availableExtras[i].id,
                name: this.quote.availableExtras[i].name,
                type: 'regular',
                quantityAdults: this.quote.availableExtras[i].quantityAdults,
                quantityChildren: this.quote.availableExtras[i].quantityChildren,
                priceAdult: this.getExtraPriceAdult(this.quote.availableExtras[i]),
                priceChild: null,
              })
            }
          }
        }
        return selectedEtras;
      },
      computedTotal() {
        return this.quote.prices.total + this.totalExtras
      },
    }
  }
</script>