import DiversConfigs from  "@/models/DiversConfigs"
import User from  "@/models/User"
import Reservation from  "@/models/Reservation"
import Currency from  "@/models/Currency"
import Extra from  "@/models/Extra"
import {NumberFormat} from "@/helpers/NumberFormat";

class Quote {
  _request
  _diversConfigs
  _user
  _discount
  _lastReservation
  _extras
  constructor(request) {
    this._request = request
    this._discount = null
  }
  async setDiversConfigs() {
    const model = new DiversConfigs
    this._diversConfigs = await model.getConfig()
    this._user = await this._getUser()
    this._currency = await this._getCurrency()
  }
  async _getUser() {
    let usersModel = new User();
    //usersModel.findByUID(currentUser.uid).get().then(qSnapshot => {
    const snap = await usersModel.findByUID(this._request.uid).get()
    let user
    snap.forEach(doc => {
      user = {id: doc.id, ...doc.data()}
    })
    return user
  }
  async _getCurrency() {
    const model = new Currency
    const doc = await model.findById(this._request.currency).get()
    return doc.data()
  }
  getSchedules () {
    return this._diversConfigs.schedules
  }
  async getPrices() {
    const totalGuides = await this._getTotalGuides()
    const totalCustomers = this._getTotalPax()
    const totalImmersions = this._getTotalImmersions()
    const price = this._diversConfigs.rates
    return {
      discount: this._discount,
      rate: price,
      lastReservation: this._lastReservation,
      totalGuides: totalGuides,
      subtotalGuides: this._request.guides * NumberFormat.round(price.diverPrice / this._currency.exchangeRate, 2),
      totalCustomers: totalCustomers,
      totalImmersions: totalImmersions,
      total:totalGuides + totalCustomers +totalImmersions,
      diverPrice: NumberFormat.round(price.diverPrice / this._currency.exchangeRate, 2),
      customerPrice: NumberFormat.round(price.customerPrice / this._currency.exchangeRate, 2),
      immersionPrice: NumberFormat.round(price.immersionPrice / this._currency.exchangeRate, 2),
      currency: this._request.currency,
      exchangeRate: this._currency.exchangeRate,
      //availabilityInformation: this.availabilityInformation()
    }
  }
  async _getTotalGuides() {
    const price = this._diversConfigs.rates
    let total
    total = this._request.guides * NumberFormat.round(price.diverPrice / this._currency.exchangeRate, 2) 
    
    if (!await this._mustChargeGuides()) {
      this._discount.value = total
      this._discount.guides = this._request.requiredGuides
      if (this._request.requiredGuides < this._request.guides) {
        total = (this._request.guides - this._request.requiredGuides) * NumberFormat.round(price.diverPrice / this._currency.exchangeRate, 2)
        this._discount.value = (this._request.guides) * NumberFormat.round(price.diverPrice / this._currency.exchangeRate, 2) - total
        return total
      }
      return 0
    }

    // this._discount = {value:0}
    return total
  }
  _getTotalPax() {
    const price = this._diversConfigs.rates
    return this._request.pax * NumberFormat.round(price.customerPrice / this._currency.exchangeRate, 2)
  }
  _getTotalImmersions() {
    const price = this._diversConfigs.rates
    return this._request.immersions * NumberFormat.round(price.immersionPrice / this._currency.exchangeRate, 2) * this._request.pax
  }
  async _mustChargeGuides() {
    // consultamos los dias desde quese registro el usuario
    // primero 90 dias no se cobra
    if (this._getDiffDaysFromNow(this._user.registerDate.seconds) <= 90) {
      this._discount = {
        title:'Guias no pagan',
        message:'Descuento por registro (los guias no pagan 90 dias apartir del registro)'
      }
      return false
    }
    //en caso de que sean mas de 90 dias se consulta la fecha de la ultima reservacion
    await this._setLastReservation()
    if (this._lastReservation && this._getDiffDaysFromNow(this._lastReservation.date.seconds) <= 90) {
      this._discount = {
        title:'Guias no pagan',
        message:'Descuento por tener una reservación 90 dias antes'
      }
      return false
    }
    return true
  }
  async _setLastReservation() {
    const model = new Reservation
    const reservations = await model.getByUserIdAndStatusCheckin(this._user.id).limit(1).get()
    let lastReservation = null
    reservations.forEach(doc => {
      lastReservation = doc.data()
    })
    this._lastReservation = lastReservation
  }
  _getDiffDaysFromNow(unixDate) {
    const now = this._request.moment()
    return now.diff(this._request.moment.unix(unixDate), 'day') 
  }
  availabilityInformation() {
    const model = new Reservation
    const dateRq = this._request.moment(this._request.date)
    return model.getReservationsDiversByDate(dateRq)
  }

  async getAvailableExtras() {
    if (!this._extras) {
      this._extras = []
      const model = new Extra
      const extras = await model.getAvailablesByPanelWithImage('divers')
      
      for (let x in extras) {
        const i = extras[x]
        let rate = {
          localAdult:this._round(i.localAdult / this._currency.exchangeRate, 2),
          localChild:this._round(i.localChild / this._currency.exchangeRate, 2),
          nationalAdult:this._round(i.nationalAdult / this._currency.exchangeRate, 2),
          nationalChild:this._round(i.nationalChild / this._currency.exchangeRate, 2),
          foreignAdult:this._round(i.foreignAdult / this._currency.exchangeRate, 2),
          foreignChild:this._round(i.foreignChild / this._currency.exchangeRate, 2),
        }
        if (this.isATV(i.categoryId)) {
          rate.localAdultShared = this._round(i.localAdultShared / this._currency.exchangeRate, 2)
          rate.foreignAdultShared = this._round(i.foreignAdultShared / this._currency.exchangeRate, 2)
          rate.nationalAdultShared = this._round(i.nationalAdultShared / this._currency.exchangeRate, 2)
        }
        let extra = {
          id: i.id,
          order: i.order,
          name: i.name,
          images: i.images,
          priceAdult: rate.foreignAdult,
          priceChild: rate.foreignChild,
          description: i.description,
          requirements: i.requirements,
          type: this.isATV(i.categoryId) ? 'atv' : 'regular',
          rate : rate,
          quantityAdults: 0,
          quantityChildren: 0,
          //...i
        }
        if (this.isATV(i.categoryId)) {
          extra.distribution = null
        }
        this._extras.push(extra)
      }
    }
    return this._extras
  }
  isATV(categoryId) {
    return categoryId === '35GgLDUefBqgKGSqiMhv'
  }
  _round(value, exp) {
    if (typeof exp === 'undefined' || +exp === 0)
    return Math.round(value);

    value = +value;
    exp = +exp;

    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0))
        return NaN;

    // Shift
    value = value.toString().split('e');
    value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp)));

    // Shift back
    value = value.toString().split('e');
    return +(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp));
}
}

export {Quote}